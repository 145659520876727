import React from 'react';
import { Box, Icon, Text } from '@agendaedu/ae-web-components';

import * as S from './styles';

import { Props } from './types';

const MessagesAttachment: React.FC<Props> = ({
  attachment,
  variation,
}): React.ReactElement => {
  return (
    <S.WrapperLink
      variation={variation}
      href={attachment.getUrl()}
      target="_blank"
      rel="noreferrer"
    >
      <Box display="flex">
        <Icon name="image" />
      </Box>
      <Text
        data-testid="attachment-name"
        variant="subtitle-medium-12"
        color="brand.primary.default"
        mb={0}
      >
        {attachment.getName()}
      </Text>
      <Text
        data-testid="attachment-size"
        variant="subtitle-medium-12"
        color="brand.primary.default"
        mb={0}
      >
        {attachment.getFormattedFileSize()}
      </Text>
    </S.WrapperLink>
  );
};

export default MessagesAttachment;
