/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment/moment';
import { useSelector, useDispatch } from 'react-redux';

import { titleMobile } from 'core/utils/changeText';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import SummarydayClassroomContext from 'core/contexts/SummarydayClassroom';

import Field from 'components/Form/Field';
import FormFieldset from 'components/Form/Fieldset';
import FileField from 'components/Form/FileField';
import Tooltip from 'components/Tooltip';
import AgendaIcon from 'components/AgendaIcon';

import { useLaunchDarkly } from 'core/hooks/useLaunchDarkly';
import { AIPromptProvider } from 'core/contexts/AIPrompt';
import { AIPromptFormModal } from '../../../../components/AIPromptFormModal';
import AIButton from 'components/AIPromptFormModal/components/AIButton';

import { Text } from '@agendaedu/ae-web-components';

import SummarydayClassroomsActions from 'store/summarydayClassrooms/actions';

import { TASKHOME_OPTIONS, SUBMIT_MODE_OPTIONS } from './constants';
import validateTextAreaReady from 'core/lib/FormValidator/validators/textAreaReady';
import validations from './validations';
import * as S from './styles';

const tooltipStyle = {
  width: '250px',
  lineHeight: '22px',
};

const GeneralInformation = ({ formContext }) => {
  const [isOpenDescriptionAIModal, setIsOpenDescriptionAiModal] =
    useState(false);
  const [isOpenTaskHomeAIModal, setIsOpenTaskHomeAiModal] = useState(false);

  const dispatch = useDispatch();

  const { form, formMeta, updateAttribute, changeMeta, hasErrorOnAttribute } =
    formContext;
  const { googleClientId } = formMeta;

  const {
    disciplines,
    formMeta: { classrooms },
  } = useSelector((state) => state.summarydayClassrooms);

  const { setClassrooms } = SummarydayClassroomsActions;
  const { token } = useContext(SummarydayClassroomContext);

  const { isActiveFlag: isActivePromptAI } = useLaunchDarkly({
    flag: 'HANDOUTS_AI',
  });

  const canGoToNextStep =
    validateTextAreaReady(form.description) &&
    validateTextAreaReady(form.page_book);

  const onChangeDiscipline = (event) => {
    updateAttribute('discipline_id', event.value);
    updateAttribute('classroom_ids', []);
    updateAttribute('classroom_with_student_profile_ids', []);
    dispatch(setClassrooms(event.value));
  };

  const onChangeDeadline = (value) => {
    const newDate = value ? moment(value) : null;
    updateAttribute('deadline', newDate);
  };

  const updatedAttributeTaskhome = (value) => {
    const updatedAttribute = {
      0: () => {
        updateAttribute('deadline', null);
        updateAttribute('answer_submit_mode', null);
      },
      1: () => {
        const { deadline, answer_submit_mode } = form;
        updateAttribute('deadline', deadline);

        if (!answer_submit_mode)
          updateAttribute('answer_submit_mode', 'agenda_edu');
      },
    };

    return updatedAttribute[value]?.() ?? 1;
  };

  const fieldSize = () => {
    const dateField = document.getElementsByClassName('DateInput_1');

    for (let element = 0; element < dateField.length; element++) {
      dateField[element].style.width = '100%';
    }
  };

  useEffect(() => {
    updatedAttributeTaskhome(form.taskhome);
    fieldSize();
  }, [form.taskhome]);

  useEffect(() => {
    if (token) updateAttribute('google_oauth_token', token);
  }, [token]);

  useEffect(() => {
    formMeta.classrooms = [...classrooms];
  }, [classrooms]);

  useEffect(() => {
    changeMeta('nextStepDisabled', !canGoToNextStep);
  }, [updateAttribute, canGoToNextStep]);

  const tooltipsLabel = ({ label, description }) => {
    return (
      <S.TooltipLabel>
        <span>{label}</span>
        <Tooltip content={description} tooltipStyle={tooltipStyle}>
          <AgendaIcon name="help" size="small" />
        </Tooltip>
      </S.TooltipLabel>
    );
  };

  const renderActivityDelivery = () => {
    return (
      <div className="row">
        <Field
          label={tooltipsLabel({
            label: 'Receber atividade através da Agenda Edu?',
            description:
              'Caso sim, você pode habilitar para que o aluno consiga enviar a atividade pelo aplicativo da Agenda. Caso não, o aluno deverá entregar em sala de aula.',
          })}
          attributeName="answer_submit_mode"
          type="simpleRadio"
          options={SUBMIT_MODE_OPTIONS}
          defaultValue={form.answer_submit_mode || 'agenda_edu'}
          formContext={formContext}
        />
      </div>
    );
  };

  const requiredField = (
    <div className="col-xs-12 col-md-12">
      <Text
        variant="body-bold-14"
        color="context.danger.default"
        mt={-16}
        mb="sm"
      >
        Campo obrigatório
      </Text>
    </div>
  );

  return (
    <FormFieldset>
      <div className="row">
        <Field
          label="Título*"
          placeholder="ex. Tarefa de casa"
          attributeName="title"
        />
      </div>

      <div className="row">
        <Field
          label="Disciplina*"
          attributeName="discipline_id"
          placeholder="Selecione"
          fullWidth
          size="large"
          mobileSize="extraLarge"
          type="selectWithIcon"
          onChange={onChangeDiscipline}
          options={disciplines}
          value={form.discipline_id}
          defaultValue={
            form.discipline_id || {
              value: null,
              label: 'Selecione a disciplina',
            }
          }
          withArrowSelect
        />
      </div>

      <div className="row">
        <Field
          label={tooltipsLabel({
            label: 'Data de cadastro*',
            description:
              'Você pode definir a data que esta atividade será publicada.',
          })}
          placeholder="Selecione uma data"
          attributeName="date"
          type="date"
        />
      </div>

      <div className="row">
        {isActivePromptAI && (
          <div className="mb-3">
            <div className="col-xs-12 col-md-12 vertical mb-2">
              <AIButton onPress={() => setIsOpenDescriptionAiModal(true)} />
            </div>
          </div>
        )}

        <Field
          label="Descrição do conteúdo*"
          quillProps={{
            onChange: (value) =>
              form.description !== value &&
              updateAttribute('description', value),
            value: form.description,
          }}
          type="textArea"
          useImage
        />

        {hasErrorOnAttribute('description') && requiredField}
      </div>

      <div className="row">
        <Field
          label="Tarefa de casa"
          attributeName="taskhome"
          type="simpleRadio"
          options={TASKHOME_OPTIONS}
          defaultValue={form.taskhome || '0'}
          formContext={formContext}
        />
      </div>

      {form.taskhome === '1' && (
        <>
          <div className="row">
            <Field
              label={tooltipsLabel({
                label: 'Data de entrega',
                description:
                  'Você pode definir a data que esta atividade deverá ser entregue pelo aluno.',
              })}
              placeholder="Selecione uma data"
              attributeName="deadline"
              onValueChange={(value) => onChangeDeadline(value)}
              type="date"
            />
          </div>

          <div className="row">
            {isActivePromptAI && (
              <div className="mb-3">
                <div className="col-xs-12 col-md-12 vertical mb-2">
                  <AIButton onPress={() => setIsOpenTaskHomeAiModal(true)} />
                </div>
              </div>
            )}

            <Field
              label="Descrição da atividade*"
              quillProps={{
                onChange: (value) =>
                  form.page_book !== value &&
                  updateAttribute('page_book', value),
                value: form.page_book,
              }}
              type="textArea"
              useImage
            />

            {hasErrorOnAttribute('page_book') && requiredField}
          </div>
        </>
      )}

      {form.taskhome === '1' && renderActivityDelivery()}

      <div className="row">
        <div className="col-xs-12">
          <FileField
            attributeName="attachments"
            googleClientId={googleClientId}
            useGoogleDrive
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <S.Info>*Campos obrigatórios</S.Info>
        </div>
      </div>

      {isActivePromptAI && (
        <AIPromptProvider feature="activity-description">
          <AIPromptFormModal
            isOpen={isOpenDescriptionAIModal}
            onClose={() => setIsOpenDescriptionAiModal(false)}
            onInsert={(value) => updateAttribute('description', value)}
          />
        </AIPromptProvider>
      )}

      {isActivePromptAI && (
        <AIPromptProvider feature="activity-task-home">
          <AIPromptFormModal
            isOpen={isOpenTaskHomeAIModal}
            onClose={() => setIsOpenTaskHomeAiModal(false)}
            onInsert={(value) => updateAttribute('page_book', value)}
          />
        </AIPromptProvider>
      )}
    </FormFieldset>
  );
};

GeneralInformation.propTypes = {
  ...formPropTypes,
};

export default tabifyForWizard({
  title: titleMobile('1 Informações'),
  subTitle: 'Informações',
  validations,
})(withFormContext(GeneralInformation));
