import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import withFormContext from 'core/hoc/withFormContext';
import validateCpf from 'core/lib/FormValidator/validators/cpf';

import ButtonRow from 'components/ButtonRow';
import RadioButton from 'components/RadioButton';
import UserItem from 'components/UserItem';
import Avatar from 'components/Avatar';
import Field from 'components/Form/Field';
import Alert from 'components/Alert';

import { ModalFormByMethodProps } from './types';
import * as S from './styles';

const ModalFormByMethod = ({
  method = 'billet',
  toggleModal,
  responsibles = [],
  onSubmit,
  formContext: {
    updateAttribute,
    addErrorOnAttribute,
    removeErrorOnAttribute,
    form,
  },
}: ModalFormByMethodProps) => {
  const { t } = useTranslation(['payments']);

  const [showInput, setShowInput] = useState(false);
  const [isInvalidRegistration, setInvalidRegistration] = useState(false);
  const policies = useSelector((state) => state.root.policies);
  const link = `/schools/responsible_profiles/${form?.responsible_profile_id}/edit`;

  const onClick = useCallback(
    (responsible) => {
      updateAttribute('cpf', '');
      updateAttribute('responsible_profile_id', responsible.id);
      setShowInput(false);
      const userAttrs = responsible?.attributes?.user?.data?.attributes;

      if (method === 'link' && !userAttrs?.document_number) {
        setShowInput(false);
        return setInvalidRegistration(true);
      }

      const pixOrZoop = method === 'pix' || isZoopProvider();

      if (pixOrZoop && !userAttrs?.has_full_data) {
        setShowInput(false);
        return setInvalidRegistration(true);
      }

      if (pixOrZoop && userAttrs?.has_full_data) {
        setShowInput(false);
        return setInvalidRegistration(false);
      }

      if (userAttrs && !!userAttrs?.document_number) {
        return updateAttribute('cpf', userAttrs.document_number);
      }

      setInvalidRegistration(false);
      return setShowInput(true);
    },
    [
      updateAttribute,
      setShowInput,
      setInvalidRegistration,
      isZoopProvider,
      method,
    ]
  );

  const isZoopProvider = useCallback(() => {
    return policies.edupay_provider === 'zoop';
  }, [policies]);

  const isDisabled = () => {
    if (method === 'pix' || isZoopProvider()) {
      return isInvalidRegistration;
    }

    return form.cpf === '';
  };

  const onSubmitModal = () => {
    const regexValidation = validateCpf(form.cpf);

    if (method === 'pix' || isZoopProvider() || regexValidation) {
      removeErrorOnAttribute('cpf');
      onSubmit(form);
    } else {
      addErrorOnAttribute('cpf', 'CPF inválido');
    }
  };

  return (
    <div data-testid="modal-form-by-method">
      <S.Responsibles data-testid="user-item">
        {responsibles.map((responsible) => (
          <S.UserItemWrapper key={responsible.id}>
            <UserItem
              onClick={() => onClick(responsible)}
              userName={responsible.attributes.name}
              userRole={responsible.attributes.role}
              highlighted={responsible.id === form.responsible_profile_id}
              avatarComponent={<Avatar user={responsible} size="small" />}
              actionComponent={
                <RadioButton
                  attributeName="responsibleId"
                  value={responsible.id}
                  checked={responsible.id === form.responsible_profile_id}
                  simpleInputRadio
                />
              }
            />
          </S.UserItemWrapper>
        ))}
      </S.Responsibles>
      {showInput && (
        <S.CpfWrapper>
          <S.FieldWrapper
            type="masked"
            mask="999.999.999-99"
            label="Digite o CPF*"
            placeholder="CPF"
            attributeName="cpf"
          />
        </S.CpfWrapper>
      )}
      {isInvalidRegistration && (
        <S.InforContainer>
          <Alert variation="danger">
            {t(`modals.generate.invalid_registration.part_1`)}
            {t(`modals.generate.invalid_registration.data_for_${method}`)}
            <Link to={link} target="_blank" onClick={toggleModal}>
              {t(`modals.generate.invalid_registration.part_2`)}
            </Link>{' '}
            {t(`modals.generate.invalid_registration.part_3`)}
          </Alert>
        </S.InforContainer>
      )}
      <S.ButtonsWrapper>
        <ButtonRow
          buttons={[
            {
              text: `${t(`modals.generate.button_back`)}`,
              variation: 'secondary',
              onClick: toggleModal,
            },
            {
              text: `${t(`modals.generate.button_generate`)}`,
              disabled: isDisabled(),
              onClick: onSubmitModal,
            },
          ]}
        />
      </S.ButtonsWrapper>
    </div>
  );
};

export default withFormContext(ModalFormByMethod);
