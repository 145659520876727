import styled, { css } from 'styled-components';
import { PropsStyle } from './types';

export const WrapperLink = styled.a<PropsStyle>`
  ${({ theme: { space, border, colors }, variation }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    word-wrap: break-word;
    word-break: break-all;
    border-radius: ${border.radius.md};
    padding: ${space.xs2};
    gap: ${space.xs};

    ${variation === 'sent'
      ? css`
          background-color: ${colors.brand.primary.default};

          p {
            color: ${colors.neutral.white};
          }
          svg {
            path {
              fill: ${colors.neutral.white};
            }
          }
        `
      : css`
          background-color: ${colors.brand.primary.op10};

          p {
            color: ${colors.brand.primary.default};
          }
          svg {
            path {
              fill: ${colors.brand.primary.default};
            }
          }
        `}
  `}
`;
