const cache: Record<string, string> = {};

const cacheKey = (color: string, alpha: number): string => `${color}-${alpha}`;

const hexToRgba = (hex: string, alpha: number): string => {
  hex = hex?.replace(/^#/, '');
  const values =
    hex?.length === 3
      ? [...hex].map((c) => parseInt(c + c, 16))
      : [0, 2, 4].map((i) => parseInt(hex?.slice(i, i + 2), 16));

  return `rgba(${values.join(', ')}, ${alpha})`;
};

const colorWithAlpha = (color: string, alpha: number): string =>
  (cache[cacheKey(color, alpha)] ||= hexToRgba(color, alpha));

export default colorWithAlpha;
